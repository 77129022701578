import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import App from "./app.jsx";

const appRouting = (<>
  <ThemeProvider
    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    minBreakpoint="xxs"
  >
    <Router>
      <Routes>
        <Route path="/" element={<App />} >
          <Route index element={<App />} />
        </Route>
      </Routes>
    </Router>
  </ThemeProvider>
</>
);
ReactDOM.render(appRouting, document.getElementById("root"));