import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

const App = () => {
  return <div className="appcontainer">
    <div className="hero-background"></div>
    <Container fluid>
      <Row><h1>How to be a cat</h1></Row>
      <Row>
        <Col> <p>How to be a cat is a game that mixes physical and digital art elements in a new interactive way. You play as a
          cat who will give you a chance to try out all of the fun things cats can do!</p></Col>

        <Col><p>Players can scan a QR code on the screen that reveals controls you can use to move the cat around the physical space.</p></Col>
      </Row>
    </Container>
  </div>;
};

export default App;